import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import styles from './OtpModal.module.css'
import '../../../assets/css/modal.css'
import { baseUrl, dataContext, portal } from '../../../App';
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useSearchParams } from 'react-router-dom';
const OtpModal = ({ showOtpModal, setShowOtpModal, activeBottom }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);
  const [otpCode, setOtpCode] = useState("");
  const [showErrorOutline, setShowErrorOutline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(``);
  const [searchParams, setSearchParams] = useSearchParams();

  const config = {
    headers: { Authorization: `Token ${localStorage.getItem("OtpAuthToken")}` },
  };
  function hideModalOperation() {
    setShowOtpModal(false);
  }
  function submitOperation() {
    console.log(otpCode)
    axios
      .post(
        `${baseUrl}/api/otpverify_api/`,
        { otp: otpCode, company_id:portal,msisdn:localStorage.getItem("user_msisdn") },
        config
      )
      .then((response) => {
        let data = response.data;
        let newToken = response.data.token;
        localStorage.setItem("AuthToken", newToken);
        setToken(newToken);
        console.log(data); 
        console.log(activeBottom);
        if(activeBottom === "campaign"){
          const paramsObject = {};
    
          for (const [key, value] of searchParams) {
            paramsObject[key] = value;
          }
          
          console.log(paramsObject);
          const config = {
            headers: { Authorization: `Token ${newToken}` },
          };
          let serviceType = 'Daily';
          if(localStorage.getItem("redirectCampaign") === "tournament"){serviceType = 'Tournament'}
          axios
          .post(
            `${baseUrl}/subscription/create/`,
            { portal: portal,"service_type": serviceType, ...paramsObject },
            config
          )
          .then((response) => {
            let data = response.data;
            setShowOtpModal(false);
            setIsLoading(false);
            console.log(data);
            if (data?.redirectURL) {
              console.log(`${data?.redirectURL}`);
              window.location.replace(`${data?.redirectURL}`);
            }
          });
        }else{
          hideModalOperation()
        }
      }).catch(error => {
        console.log(error);
        if (error?.response?.data?.error) {
          setShowError(true);
          setErrorMessage(error?.response?.data?.error)
        }
      })
  }




  return (
    <>
      <Modal show={showOtpModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation} backdrop="static">
        <Modal.Header >
          <Modal.Title>প্রোফাইল একটিভ করুন</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className={`inputSpan`}>
            {" "}
            <input
              type="number"
              maxLength={6}
              minLength={6}
              onInput={(e) => {
                setOtpCode(e.target.value);
                setShowError(false)
              }}
              id="otpCode"
              name="otpCode"
              placeholder="OTP"
              className={styles.inputBox}
              value={otpCode}
            />{" "}
          </span>
          {showError && <div style={{ color: "#ff0000", textAlign: 'center', fontWeight: 600 }}>* {errorMessage}</div>}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`btn ${styles.redeemButton}`}
            onClick={submitOperation}
          >
            {isLoading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default OtpModal